import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Box,
  IconButton,
  TextField,
  Chip,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ERP from "../../util/ERP";
import { notificationManager } from "../../managers/NotificationManager";
import {
  CompletionType,
  getOperationCompletionType,
  WorkOrder,
} from "../../util/Typings";
import { isTaggedTemplateExpression } from "typescript";

interface OperationData {
  opNum: number;
  desc: string;
  resource: string;
  workCell?: string;
  quantityTotal?: number;
  opType: string;
  complete?: string; // Format: "completed/total"
}

interface TimeTrackingMenuProps {
  WorkorderId: string;
  open: boolean;
  onClose: () => void;
}

export default function TimeTrackingMenu({
  WorkorderId,
  open,
  onClose,
}: TimeTrackingMenuProps) {
  const [operations, setOperations] = useState<OperationData[]>([]);
  const [oldValues, setOldValues] = useState<Record<string, number>>({});
  const [changes, setChanges] = useState<Record<string, number>>({});
  const [isSaving, setIsSaving] = useState(false); // Tracks saving state
  const fetchData = async () => {
    try {
      const workOrderData = await ERP.GetObject<WorkOrder>(
        "workorder",
        WorkorderId
      );

      const partId = workOrderData.part;
      const ops = await ERP.Parts.GetPartOperations("part", partId);
      const workCells = await ERP.GetWorkCellsByWO(WorkorderId);

      const formattedOperations = ops.map((op: any) => {
        const matchingWorkCell = workCells.find(
          (cell: any) => Number(cell.opnum) === Number(op.opNum)
        );

        const quantityComplete = matchingWorkCell
          ? matchingWorkCell.quantity_complete
          : 0;

        if (matchingWorkCell?.id) {
          setOldValues((prev) => ({
            ...prev,
            [matchingWorkCell.id]: quantityComplete,
          }));
        }

        return {
          ...op,
          workCell: matchingWorkCell ? matchingWorkCell.id : undefined,
          quantityTotal: matchingWorkCell
            ? matchingWorkCell.quantity_total
            : undefined,
          complete: matchingWorkCell
            ? `${quantityComplete}/${matchingWorkCell.quantity_total}`
            : undefined,
        };
      });

      setOperations(formattedOperations);
      setChanges({}); // Reset changes when new data is loaded
    } catch (err) {
      notificationManager.notify("error", `Failed to load data: ${err}`);
    }
  };

  const handleActionUpdate = (
    workCellId: string,
    type: CompletionType,
    value: any
  ) => {
    setOperations((prevOps) =>
      prevOps.map((op) => {
        if (op.workCell === workCellId) {
          const quantityTotal = op.quantityTotal || 0;
          const currentComplete = Number(op.complete?.split("/")[0]) || 0;

          let newComplete = currentComplete;
          let increment = 0;

          if (type === "bool") {
            increment = value
              ? quantityTotal - currentComplete
              : -currentComplete;
            newComplete = value ? quantityTotal : 0;
          } else if (type === "percent") {
            increment = (quantityTotal * value) / 100;
            newComplete = Math.min(currentComplete + increment, quantityTotal);
          } else if (type === "int") {
            increment = value - currentComplete;
            newComplete = Math.min(Math.max(0, value), quantityTotal);
          }

          setChanges((prevChanges) => ({
            ...prevChanges,
            [workCellId]: (prevChanges[workCellId] || 0) + increment,
          }));

          return {
            ...op,
            complete: `${newComplete}/${quantityTotal}`,
          };
        }
        return op;
      })
    );
  };

  const handleSave = () => {
    setIsSaving(true);
    const transactions: { workCell: string; newValue: number }[] = [];
    operations.forEach((op) => {
      if (op.workCell) {
        const oldValue = oldValues[op.workCell] || 0;
        const newValue = Number(op.complete?.split("/")[0]) || 0;
        if (newValue !== oldValue) {
          transactions.push({
            workCell: op.workCell,
            newValue,
          });
        }
      }
    });

    ERP.RegisterProgressTransactions(transactions).finally(() => {
      setIsSaving(false); // Stop saving
      setChanges({}); // Reset changes after save
      onClose(); // Close dialog
      window.location.reload();
    });
  };

  const handleCancel = () => {
    setChanges({});
    onClose();
  };

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="md">
      <DialogTitle>Progress Tracking Center</DialogTitle>
      <DialogContent dividers>
        {isSaving ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>OP#</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Completion</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {operations.map((op, index) => {
                  const type = getOperationCompletionType(op.opType);
                  const maxValue = op.quantityTotal || 0;
                  const currentComplete =
                    Number(op.complete?.split("/")[0]) || 0;

                  return (
                    <TableRow key={index}>
                      <TableCell>{op.opNum}</TableCell>
                      <TableCell>{op.desc}</TableCell>
                      <TableCell>
                        <Chip
                          color={
                            currentComplete >= maxValue ? "success" : "error"
                          }
                          label={
                            type === "int"
                              ? op.complete || "?"
                              : type === "bool"
                              ? currentComplete === maxValue
                                ? "Yes"
                                : "No"
                              : `${((currentComplete / maxValue) * 100).toFixed(
                                  0
                                )}%`
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {type === "bool" && (
                          <Checkbox
                            checked={currentComplete === maxValue}
                            onChange={(e) =>
                              handleActionUpdate(
                                op.workCell || "",
                                "bool",
                                e.target.checked
                              )
                            }
                          />
                        )}
                        {type === "int" && (
                          <Box display="flex" alignItems="center" gap={1}>
                            <TextField
                              type="number"
                              size="small"
                              defaultValue={currentComplete}
                              inputProps={{
                                min: 0,
                                max: maxValue,
                                step: 1,
                              }}
                              onChange={(e) =>
                                handleActionUpdate(
                                  op.workCell || "",
                                  "int",
                                  Math.min(
                                    Math.max(0, Number(e.target.value)),
                                    maxValue
                                  )
                                )
                              }
                            />
                          </Box>
                        )}
                        {type === "percent" && (
                          <Box display="flex" gap={1}>
                            {[1, 5, 10, 25, 50].map((step) => (
                              <IconButton
                                key={step}
                                size="small"
                                color="primary"
                                sx={{
                                  fontSize: "0.75rem",
                                }}
                                onClick={() =>
                                  handleActionUpdate(
                                    op.workCell || "",
                                    "percent",
                                    step
                                  )
                                }
                              >
                                +{step}%
                              </IconButton>
                            ))}
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      {!isSaving && (
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleSave}
            disabled={Object.keys(changes).length === 0 || isSaving} // Disable if no changes or saving
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCancel}
            disabled={isSaving} // Disable during saving
          >
            Cancel
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
