import * as React from "react";

import { ObjectType, Part } from "../../util/Typings";
import { useNavigate } from "react-router-dom";
import CollectionOverview from "../Core/CollectionOverview";

export default function ObjectOverview(componentProps: {
  objectType: ObjectType;
  filter?: { [key: string]: string };
}) {
  const navigate = useNavigate();
  const handleCellClick = (rawParams: unknown) => {
    // Assuming you want to navigate to a user detail page
    //navigate(`/user/${params.id}`);  // Adjust the path as needed
    const params = rawParams as { id: string; field: string };
    switch (params.field) {
      case "name":
        navigate(`${params.id}`);
        break;
    }
  };
  const displayFields = [
    {
      name: "name",
      display: "Name",
    },
  ];
  switch (componentProps.objectType) {
    case "rate":
      displayFields.push({
        name: "description",
        display: "Desc",
      });
      displayFields.push({
        name: "cost_per_hour",
        display: "$/hr",
      });
      break;
    case "part":
      displayFields.push({
        name: "client",
        display: "Client",
      });
      displayFields.push({
        name: "quantity_inventory",
        display: "Stock",
      });
      break;
    case "customerEmail":
      displayFields.push({
        name: "sender",
        display: "Sender",
      });
      displayFields.push({
        name: "status",
        display: "Status",
      });
      displayFields.push({
        name: "subject",
        display: "Subject",
      });
      displayFields.push({
        name: "preview",
        display: "Preview",
      });
      displayFields.push({
        name: "receivedDate",
        display: "Date",
      });
      break;
    case "user":
      displayFields.push({
        name: "role",
        display: "User Role",
      });
      displayFields.push({
        name: "permission_level",
        display: "Permission Level",
      });
      break;
    case "rfq":
      displayFields.push({
        name: "client",
        display: "Client",
      });
      displayFields.push({
        name: "due_date",
        display: "Respond By (Due)",
      });
      break;
    case "cpo":
      displayFields.push({
        name: "client",
        display: "Client",
      });
      displayFields.push({
        name: "status",
        display: "Status",
      });
      break;
    case "vpo":
      displayFields.push({
        name: "vendor",
        display: "Vendor",
      });
      displayFields.push({
        name: "status",
        display: "Status",
      });
      break;
    case "cot":
      displayFields.push({
        name: "type",
        display: "COT Type",
      });
      displayFields.push({
        name: "supplier",
        display: "Supplier",
      });
      displayFields.push({
        name: "quantity_inventory",
        display: "Stock",
      });
      break;
    case "contact":
      displayFields.push({
        name: "email",
        display: "E-mail",
      });
      displayFields.push({
        name: "type",
        display: "Contact Type",
      });
      break;
    case "estimate":
      displayFields.push({
        name: "description",
        display: "Desc",
      });
      displayFields.push({
        name: "client",
        display: "Client",
      });
      break;
    case "workorder":
      displayFields.push({
        name: "status",
        display: "Status",
      });
      displayFields.push({
        name: "due_date",
        display: "Due Date",
      });
      break;
  }
  return (
    <CollectionOverview
      objectType={componentProps.objectType}
      onCellClick={handleCellClick}
      filter={componentProps.filter}
      displayFields={displayFields}
    />
  );
}
