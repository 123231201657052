import {
  Box,
  Card,
  Chip,
  IconButton,
  MenuItem,
  NativeSelect,
  Typography,
} from "@mui/material";
import EditTools from "./EditTools";
import { useEffect, useState } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select"; // Import SelectChangeEvent
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteIcon from "@mui/icons-material/Delete";
import DynamicProp from "../../util/DynamicProp";
import ERP from "../../util/ERP";
import { notificationManager } from "../../managers/NotificationManager";
import { Operation } from "../../util/Typings";

const CellStyle = {
  borderRight: "2px solid rgba(0, 0, 0, 0.1)", // Adjust the thickness and color here
  minWidth: 100,
  borderBottom: "2px solid rgba(0, 0, 0, 0.1)",
};

type FieldName =
  | "opNum"
  | "desc"
  | "resource"
  | "opType"
  | "setup"
  | "setupNR"
  | "cycle"
  | "changeOut"
  | "inspection"
  | "inspectionNR"
  | "breakDown"
  | "pctLaborTime"
  | "minPerPart"
  | "minPerPartReal"
  | "setupReal";

type DataPoint = {
  opNum: number;
  desc: string;
  resource: string;
  opType: string;
  setup: number;
  setupNR: number;
  setupReal?: number;
  cycle: number;
  changeOut: number;
  inspection: number;
  inspectionNR: number;
  breakDown: number;
  pctLaborTime: number;
  minPerPart: number;
  minPerPartReal?: number; // PART ONLY
};
type Data = DataPoint[];
export type PartOperationsProps = {
  partId: string;
  isEstimate: boolean;
  readOnly?: boolean;
};

export default function PartOperations(props: PartOperationsProps) {
  const [isEditing, setEditMode] = useState(false);
  const [tableData, setTableData] = useState<Data>([]);
  const [editIdx, setEditIdx] = useState(-1); // Track the row being edited
  const [editCount, setEditCount] = useState(0);

  useEffect(() => {
    const getOps = () => {
      ERP.Parts.GetPartOperations(
        props.isEstimate ? "estimate" : "part",
        props.partId
      )
        .then((ops) => {
          setTableData(ops as unknown as Data); // FIX LATER
        })
        .catch((err) => {
          console.log("OPS: NONE for " + props.partId);
        });
    };

    getOps();
  }, []);

  // Handle input change for editable fields
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: FieldName,
    index: number
  ) => {
    const newData = [...tableData];
    newData[index] = { ...newData[index], [field]: e.target.value }; // Update the relevant field
    setTableData(newData);
    setEditCount(editCount + 1);
  };

  const handleSelectChange = (
    index: number,
    fieldName: string,
    value: string
  ) => {
    const newData = [...tableData];
    newData[index] = { ...newData[index], [fieldName]: value };
    setTableData(newData);
    setEditCount(editCount + 1);
  };

  // row arrangement
  const handleMoveUp = (index: number) => {
    const newData = [...tableData];
    [newData[index - 1], newData[index]] = [newData[index], newData[index - 1]];
    setTableData(newData);
    setEditCount(editCount + 1);
  };

  const handleMoveDown = (index: number) => {
    const newData = [...tableData];
    [newData[index + 1], newData[index]] = [newData[index], newData[index + 1]];
    setTableData(newData);
    setEditCount(editCount + 1);
  };

  const handleDeleteRow = (index: number) => {
    const newData = tableData.filter((_, i) => i !== index);
    setTableData(newData);
    setEditCount(editCount + 1);
  };

  // Add new blank operation
  const handleAddNewOperation = () => {
    const newOperation: DataPoint = {
      opNum: 0,
      desc: "",
      resource: "",
      opType: "",
      setup: 0,
      setupNR: 0,
      cycle: 0,
      changeOut: 0,
      inspection: 0,
      inspectionNR: 0,
      breakDown: 0,
      pctLaborTime: 0,
      minPerPart: 0,
      setupReal: props.isEstimate ? undefined : 0,
      minPerPartReal: props.isEstimate ? undefined : 0,
    };

    setTableData([...tableData, newOperation]);
    setEditIdx(tableData.length); // Set the newly added row to be editable
    setEditCount(editCount + 1);
  };

  function handleToggleEditMode(editing: boolean) {
    setEditMode(editing);
  }
  function handleSave() {
    if (editCount > 0) {
      const parsedData = tableData;
      ERP.Parts.UpdatePartOperations(
        props.isEstimate ? "estimate" : "part",
        props.partId,
        parsedData as unknown as Operation[]
      )
        .then((updatedData) => {
          console.log(updatedData);
          notificationManager.notify("success", "Changes saved.");
        })
        .catch((err) => {
          notificationManager.notify(
            "error",
            "Changes could not be saved: " + err
          );
        });
    } else {
      notificationManager.notify("warning", "No changes to save.");
    }
    setEditMode(false);
  }
  return (
    <Card sx={{ width: "100%", marginTop: 2 }}>
      <Box display="flex" flexDirection="row" justifyContent={"space-between"}>
        <Typography
          variant="h4"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            verticalAlign: "bottom",
          }}
        >
          Operations
        </Typography>
        {!props.readOnly && (
          <EditTools
            isEditing={isEditing}
            toggleEdit={handleToggleEditMode}
            onSave={handleSave}
          />
        )}
      </Box>
      <br />
      <TableContainer component={Paper} sx={{}}>
        <Table size="small" sx={{}}>
          <TableHead>
            <TableRow
              sx={{
                borderBottom: "2px solid rgba(0, 0, 0, 0.1)", // Adjust the thickness and color here
              }}
            >
              <TableCell sx={CellStyle}>OP#</TableCell>
              <TableCell sx={{ ...CellStyle, minWidth: 200 }}>Desc</TableCell>
              <TableCell sx={{ ...CellStyle, minWidth: 150 }}>
                Resource
              </TableCell>
              {props.isEstimate && (
                <TableCell sx={{ ...CellStyle, minWidth: 150 }}>
                  Op Type
                </TableCell>
              )}
              <TableCell sx={CellStyle}>Set-Up</TableCell>
              <TableCell sx={CellStyle}>NR Set-Up</TableCell>
              {!props.isEstimate && (
                <TableCell sx={CellStyle}>
                  <Chip label="EST. Set-Up" variant="outlined" color="info" />
                </TableCell>
              )}
              <TableCell sx={CellStyle}>Cycle</TableCell>
              <TableCell sx={CellStyle}>Change Out</TableCell>
              <TableCell sx={CellStyle}>Inspection</TableCell>
              <TableCell sx={CellStyle}>NR Inspection</TableCell>
              <TableCell sx={CellStyle}>Break Down</TableCell>
              <TableCell sx={CellStyle}>% Labor Time</TableCell>
              <TableCell sx={!props.isEstimate ? CellStyle : {}}>
                Min/Part
              </TableCell>
              {!props.isEstimate && (
                <TableCell>
                  <Chip label="EST. Min/Part" variant="outlined" color="info" />
                </TableCell>
              )}
              {isEditing && (
                <TableCell sx={{ ...CellStyle, minWidth: 200 }}>
                  Actions
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow
                key={index}
                sx={index % 2 !== 0 ? {} : { backgroundColor: "white" }}
              >
                {/* Editable OP# Cell */}
                <TableCell sx={CellStyle}>
                  {isEditing ? (
                    <TextField
                      type="number"
                      value={row.opNum}
                      onChange={(e) => handleChange(e, "opNum", index)}
                    />
                  ) : (
                    row.opNum
                  )}
                </TableCell>

                {/* Editable Desc Cell */}
                <TableCell sx={CellStyle}>
                  {isEditing ? (
                    <TextField
                      value={row.desc}
                      onChange={(e) => handleChange(e, "desc", index)}
                    />
                  ) : (
                    row.desc
                  )}
                </TableCell>
                {/* Editable Resource Dropdown */}
                <TableCell sx={CellStyle}>
                  <DynamicProp
                    value={row.resource}
                    name="resource"
                    editMode={isEditing}
                    condensed
                    onEdit={(newValue) => {
                      handleSelectChange(index, "resource", newValue as string);
                    }}
                  />
                </TableCell>

                {props.isEstimate && (
                  <TableCell sx={CellStyle}>
                    <DynamicProp
                      value={row.opType}
                      name="opType"
                      editMode={isEditing}
                      condensed
                      onEdit={(newValue) => {
                        handleSelectChange(index, "opType", newValue as string);
                      }}
                    />
                  </TableCell>
                )}

                <TableCell sx={CellStyle}>
                  {isEditing ? (
                    <TextField
                      type="number"
                      value={props.isEstimate ? row.setup : row.setupReal}
                      onChange={(e) =>
                        handleChange(
                          e,
                          props.isEstimate ? "setup" : "setupReal",
                          index
                        )
                      }
                    />
                  ) : props.isEstimate ? (
                    row.setup
                  ) : (
                    row.setupReal
                  )}
                </TableCell>
                <TableCell sx={CellStyle}>
                  {isEditing ? (
                    <TextField
                      type="number"
                      value={row.setupNR}
                      onChange={(e) => handleChange(e, "setupNR", index)}
                    />
                  ) : (
                    row.setupNR
                  )}
                </TableCell>
                {!props.isEstimate && (
                  <TableCell sx={CellStyle}>{row.setup}</TableCell>
                )}

                <TableCell sx={CellStyle}>
                  {isEditing ? (
                    <TextField
                      type="number"
                      value={row.cycle}
                      onChange={(e) => handleChange(e, "cycle", index)}
                    />
                  ) : (
                    row.cycle
                  )}
                </TableCell>
                <TableCell sx={CellStyle}>
                  {isEditing ? (
                    <TextField
                      type="number"
                      value={row.changeOut}
                      onChange={(e) => handleChange(e, "changeOut", index)}
                    />
                  ) : (
                    row.changeOut
                  )}
                </TableCell>
                <TableCell sx={CellStyle}>
                  {isEditing ? (
                    <TextField
                      type="number"
                      value={row.inspection}
                      onChange={(e) => handleChange(e, "inspection", index)}
                    />
                  ) : (
                    row.inspection
                  )}
                </TableCell>
                <TableCell sx={CellStyle}>
                  {isEditing ? (
                    <TextField
                      type="number"
                      value={row.inspectionNR}
                      onChange={(e) => handleChange(e, "inspectionNR", index)}
                    />
                  ) : (
                    row.inspectionNR
                  )}
                </TableCell>
                <TableCell sx={CellStyle}>
                  {isEditing ? (
                    <TextField
                      type="number"
                      value={row.breakDown}
                      onChange={(e) => handleChange(e, "breakDown", index)}
                    />
                  ) : (
                    row.breakDown
                  )}
                </TableCell>
                <TableCell sx={CellStyle}>
                  {isEditing ? (
                    <TextField
                      type="number"
                      value={row.pctLaborTime}
                      onChange={(e) => handleChange(e, "pctLaborTime", index)}
                    />
                  ) : (
                    row.pctLaborTime
                  )}
                </TableCell>
                {!props.isEstimate && (
                  <TableCell sx={props.isEstimate ? {} : CellStyle}>
                    {isEditing ? (
                      <TextField
                        type="number"
                        value={row.minPerPartReal}
                        onChange={(e) =>
                          handleChange(e, "minPerPartReal", index)
                        }
                      />
                    ) : (
                      row.minPerPartReal
                    )}
                  </TableCell>
                )}
                <TableCell sx={props.isEstimate ? {} : CellStyle}>
                  {isEditing && props.isEstimate ? (
                    <TextField
                      type="number"
                      value={row.minPerPart}
                      onChange={(e) => handleChange(e, "minPerPart", index)}
                    />
                  ) : (
                    row.minPerPart
                  )}
                </TableCell>
                {/** ACTIONS */}
                {isEditing && (
                  <TableCell>
                    <IconButton
                      onClick={() => handleMoveUp(index)}
                      disabled={index === 0}
                    >
                      <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleMoveDown(index)}
                      disabled={index === tableData.length - 1}
                    >
                      <ArrowDownwardIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteRow(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isEditing && (
        <Button
          sx={{ marginTop: 2 }}
          variant="contained"
          onClick={handleAddNewOperation}
        >
          Add New Operation
        </Button>
      )}
    </Card>
  );
}
