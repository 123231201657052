import { createContext, useEffect, useState } from "react";
import { onAuthStateChanged, User } from "firebase/auth";
import FB from "../util/Firebase";
import ERP from "../util/ERP";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

interface AuthContextType {
  currentUser: User | null;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const navigate = useNavigate(); // Hook to navigate to login page
  const [loading, setLoading] = useState(true); // To handle loading state

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(FB.auth, async (user) => {
      if (user) {
        try {
          // Try to get the user's token and force refresh if needed
          const token = await user.getIdToken(true); // Force refresh
          if (token) {
            ERP.LocalUser.SetCredential(user.email as string, token);
            setCurrentUser(user); // Set the user data in the state
          }
        } catch (error) {
          // Token expired or any error in getting the token
          console.error("Token expired or invalid", error);
          handleLogout();
        }
      } else {
        // User is not logged in
        setCurrentUser(null);
      }
      setLoading(false); // Stop loading after checking the auth state
    });

    return () => unsubscribe(); // Clean up the listener on unmount
  }, []);

  const handleLogout = () => {
    // Perform logout and redirect to login screen
    FB.auth.signOut();
    navigate("/login"); // Redirect user to the login page
  };

  if (loading) {
    return <CircularProgress />; // Show loading state while checking auth status
  }

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
