import * as React from "react";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import type {} from "@mui/x-charts/themeAugmentation";
import type {} from "@mui/x-data-grid/themeAugmentation";
import type {} from "@mui/x-tree-view/themeAugmentation";
import { alpha } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import AppNavbar from "../components/AppNavbar";
import Header from "../components/Header";
import { useState, useEffect } from "react";
import SideMenu from "../components/SideMenu";
import PartOperations from "../components/Core/PartOperations";

import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,
} from "../theme/customizations";
import AppTheme from "../shared-theme/AppTheme";
import DynamicProp from "../util/DynamicProp";
import {
  Button,
  Card,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";
import DriveAccess from "./DriveAccess";
import { Object3D } from "three";
import ObjViewer from "../components/3D/ObjViewer";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid } from "@react-three/drei";
import EditTools from "../components/Core/EditTools";
import ERP from "../util/ERP";
import { notificationManager } from "../managers/NotificationManager";

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};

function validateURL(url: string): boolean {
  const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
  return pattern.test(url);
}

export default function SystemConfig(props: { disableCustomTheme?: boolean }) {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [config, setConfig] = useState({
    TANDC: "test",
    FILESURL: "test",
  });
  const [urlValid, setUrlValid] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      ERP.System.GetConfig()
        .then(async (c) => {
          const conf = await c.json();
          setConfig(conf);
        })
        .catch((err) => {
          notificationManager.notify("error", err);
        });
    };
    getData();
  }, []);

  const toggleEdit = () => {
    setIsEditing((prev) => !prev);
  };

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const url = event.target.value;
    setConfig((prev) => ({ ...prev, FILESURL: url }));
    setUrlValid(validateURL(url));
  };

  const clearUrl = () => {
    setConfig((prev) => ({ ...prev, FILESURL: "" }));
    setUrlValid(false);
  };

  return (
    <AppTheme {...props} themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: "flex" }}>
        <SideMenu />
        <AppNavbar />
        {/* Main content */}
        <Box
          component="main"
          sx={(theme) => ({
            flexGrow: 1,
            backgroundColor: theme.palette.background.default
              ? `rgba(${theme.palette.background.default} / 1)`
              : alpha(theme.palette.background.default, 1),
            overflow: "auto",
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: "left",
              mx: 3,
              pb: 10,
              mt: { xs: 8, md: 0 },
            }}
          >
            <Header />
            <Card
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography variant="h4">System Config Menu</Typography>
              <EditTools
                isEditing={isEditing}
                toggleEdit={toggleEdit}
                onSave={() => {
                  //
                }}
              />
            </Card>

            <DynamicProp
              name={`File Server URL ${
                validateURL(config.FILESURL) ? "✅" : "❌"
              }`}
              value={config.FILESURL}
              editMode={isEditing}
              onEdit={(value) => {
                setConfig((prevConfig) => {
                  return { ...prevConfig, FILESURL: value as string };
                });
              }}
            />
          </Stack>
        </Box>
      </Box>
    </AppTheme>
  );
}
