import {
  Box,
  Card,
  IconButton,
  Typography,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import EditTools from "./EditTools";
import { useEffect, useState } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteIcon from "@mui/icons-material/Delete";
import { notificationManager } from "../../managers/NotificationManager";
import ERP from "../../util/ERP";
import { ObjectType, Part } from "../../util/Typings";
import DynamicProp from "../../util/DynamicProp";
import { Propane } from "@mui/icons-material";

type FieldName = "itemNumber" | "desc" | "qty" | "supplier" | "costPerPart";

type Data = {
  itemType: string;
  itemNumber: string;
  qty: number;
}[];

export type PartOperationsProps = {
  isEstimate: boolean;
  docId: string;
  readOnly?: boolean;
};

const RenderDynamicPropValue = (props: {
  objectId: string;
  objectType: ObjectType;
  propName: string;
}) => {
  const [propValue, setPropValue] = useState("?");
  useEffect(() => {
    const getData = () => {
      ERP.GetObject(props.objectType, props.objectId)
        .then((obj) => {
          const object = obj as unknown as { [key: string]: string };
          if (object[props.propName]) {
            setPropValue(object[props.propName]);
          }
        })
        .catch((err) => {
          setPropValue("err");
        });
    };

    getData();
  }, []);
  return (
    <>
      {props.propName === "supplier" ? (
        <DynamicProp
          name="supplier"
          objectType="contact"
          value={propValue}
          condensed
        />
      ) : (
        propValue
      )}
    </>
  );
};

export default function BOM(props: PartOperationsProps) {
  const [isEditing, setEditMode] = useState(false);
  const [tableData, setTableData] = useState<Data>([]);
  const [editCount, setEditCount] = useState(0);

  useEffect(() => {
    const fetchBOM = async () => {
      const data = await ERP.GetObject<Part>(
        props.isEstimate ? "estimate" : "part",
        props.docId
      );
      setTableData((data?.bom as Data) || []);
    };
    fetchBOM();
  }, [props.docId]);

  const handleChange = (
    e: string | number,
    field: FieldName,
    index: number
  ) => {
    const newData = [...tableData];
    newData[index] = { ...newData[index], [field]: e };
    setTableData(newData);
    setEditCount(editCount + 1);
  };

  const handleSelectChange = (value: string, index: number) => {
    const newData = [...tableData];
    newData[index] = { ...newData[index], itemType: value };
    setTableData(newData);
    setEditCount(editCount + 1);
  };

  const handleMoveUp = (index: number) => {
    const newData = [...tableData];
    [newData[index - 1], newData[index]] = [newData[index], newData[index - 1]];
    setTableData(newData);
    setEditCount(editCount + 1);
  };

  const handleMoveDown = (index: number) => {
    const newData = [...tableData];
    [newData[index + 1], newData[index]] = [newData[index], newData[index + 1]];
    setTableData(newData);
    setEditCount(editCount + 1);
  };

  const handleDeleteRow = (index: number) => {
    const newData = tableData.filter((_, i) => i !== index);
    setTableData(newData);
    setEditCount(editCount + 1);
  };

  const handleAddNewItem = () => {
    const newItem = {
      itemType: "Part",
      itemNumber: "",
      qty: 0,
    };
    setTableData([...tableData, newItem]);
    setEditCount(editCount + 1);
  };

  const handleSave = () => {
    if (editCount > 0) {
      ERP.UpdateObject("estimate", props.docId, [
        { name: "bom", value: tableData as unknown as string },
      ])
        .then(() => {
          notificationManager.notify(
            "success",
            `Saved changes. (${editCount})`
          );
          setEditCount(0);
        })
        .catch((err) =>
          notificationManager.notify("error", `Failed to save changes: ${err}`)
        );

      ERP.UpdatePartCost(props.docId)
        .then((success) => {
          window.location.reload();
        })
        .catch((err) => {
          //
        });
      setEditMode(false);
    }
  };
  return (
    <Card sx={{ width: "100%", marginTop: 2 }}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4">Bill Of Materials</Typography>
        {!props.readOnly && (
          <EditTools
            isEditing={isEditing}
            toggleEdit={setEditMode}
            onSave={handleSave}
          />
        )}
      </Box>
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Ln. #</b>
              </TableCell>
              <TableCell>
                <b>Item Type</b>
              </TableCell>
              <TableCell>
                <b>Item#</b>
              </TableCell>
              <TableCell>
                <b>Quantity</b>
              </TableCell>
              {!isEditing && (
                <TableCell>
                  <b>Description</b>
                </TableCell>
              )}
              {!isEditing && (
                <TableCell>
                  <b>Supplier</b>
                </TableCell>
              )}
              {!isEditing && (
                <TableCell>
                  <b>Unit Cost</b>
                </TableCell>
              )}
              {isEditing && (
                <TableCell>
                  <b>Actions</b>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  {isEditing ? (
                    <Select
                      value={row.itemType}
                      onChange={(e) =>
                        handleSelectChange(e.target.value, index)
                      }
                    >
                      <MenuItem value="Part">Part</MenuItem>
                      <MenuItem value="Cots">Cots</MenuItem>
                    </Select>
                  ) : (
                    row.itemType
                  )}
                </TableCell>
                <TableCell>
                  <DynamicProp
                    name={row.itemType === "Part" ? "part" : "cots"}
                    value={row.itemNumber}
                    selectOptionsFilter={[props.docId]}
                    condensed
                    editMode={isEditing}
                    onEdit={(val) => {
                      handleChange(val as string, "itemNumber", index);
                    }}
                  />
                </TableCell>
                <TableCell>
                  {isEditing ? (
                    <TextField
                      type="number"
                      value={row.qty}
                      onChange={(e) =>
                        handleChange(e.target.value, "qty", index)
                      }
                    />
                  ) : (
                    row.qty
                  )}
                </TableCell>
                {!isEditing && (
                  <TableCell>
                    <RenderDynamicPropValue
                      objectType={row.itemType === "Part" ? "part" : "cot"}
                      propName="description"
                      objectId={row.itemNumber}
                    />
                  </TableCell>
                )}
                {!isEditing && (
                  <TableCell>
                    <RenderDynamicPropValue
                      objectType={row.itemType === "Part" ? "part" : "cot"}
                      propName="supplier"
                      objectId={row.itemNumber}
                    />
                  </TableCell>
                )}
                {!isEditing && (
                  <TableCell>
                    <RenderDynamicPropValue
                      objectType={row.itemType === "Part" ? "part" : "cot"}
                      propName="pricePer"
                      objectId={row.itemNumber}
                    />
                  </TableCell>
                )}
                {isEditing && (
                  <TableCell>
                    <IconButton
                      onClick={() => handleMoveUp(index)}
                      disabled={index === 0}
                    >
                      <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleMoveDown(index)}
                      disabled={index === tableData.length - 1}
                    >
                      <ArrowDownwardIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteRow(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isEditing && (
        <Button
          sx={{ marginTop: 2 }}
          variant="contained"
          onClick={handleAddNewItem}
        >
          Add New Item
        </Button>
      )}
    </Card>
  );
}
