import { Box, Card, Chip, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import DynamicProp from "../../util/DynamicProp";
import ERP from "../../util/ERP";
import { notificationManager } from "../../managers/NotificationManager";
import TimeTrackingMenu from "../TimeTracking/TimeTrackingMenu";
import { getOperationCompletionType } from "../../util/Typings";

type Data = {
  opNum: number;
  desc: string;
  rate: string;
  resource: string;
  opType: string;
  workCell?: string;
  complete?: string;
}[];

export type PartOperationsProps = {
  workOrder: string;
  partId: string;
  readOnly?: boolean;
};

export default function WorkOrderOperations(props: PartOperationsProps) {
  const [isEditing, setEditMode] = useState(false);
  const [tableData, setTableData] = useState<Data>([]);
  const [editIdx, setEditIdx] = useState(-1); // Track the row being edited
  const [editCount, setEditCount] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    const getOps = () => {
      if (props.partId) {
        ERP.Parts.GetPartOperations("part", props.partId)
          .then((ops) => {
            ERP.GetWorkCellsByWO(props.workOrder)
              .then((workcells) => {
                const d = ops.map((op) => {
                  const filteredCells = workcells.filter((c) => {
                    return Number(c.opnum) === Number(op.opNum);
                  });
                  return {
                    ...op,
                    workCell: filteredCells[0]
                      ? filteredCells[0].id
                      : undefined,
                    complete: filteredCells[0]
                      ? `${filteredCells[0].quantity_complete}/${filteredCells[0].quantity_total}`
                      : undefined,
                  };
                });
                setTableData(d);
              })
              .catch((err) => {
                notificationManager.notify(
                  "error",
                  `Failed to load work cells: ${err}`
                );
              });
          })
          .catch((err) => {
            notificationManager.notify("error", err);
          });
      }
    };

    getOps();
  }, [props.partId, props.workOrder]);

  const handleSelectChange = (
    index: number,
    fieldName: string,
    value: string
  ) => {
    const newData = [...tableData];
    newData[index] = { ...newData[index], [fieldName]: value };
    setTableData(newData);
    setEditCount(editCount + 1);
  };

  return (
    <Card sx={{ width: "100%", marginTop: 2 }}>
      <Box display="flex" flexDirection="row" justifyContent={"space-between"}>
        <Typography
          variant="h4"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            verticalAlign: "bottom",
          }}
        >
          Progress
        </Typography>
        {!props.readOnly && (
          <IconButton
            aria-label="time tracking menu"
            onClick={handleDialogOpen}
          >
            <AccessTimeIcon />
          </IconButton>
        )}
      </Box>
      <TimeTrackingMenu
        WorkorderId={props.workOrder}
        open={dialogOpen}
        onClose={handleDialogClose}
      />
      <br />
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
              }}
            >
              <TableCell>OP#</TableCell>
              <TableCell>Desc</TableCell>
              <TableCell>Resource</TableCell>
              <TableCell>Work Cell</TableCell>
              <TableCell>Complete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.opNum}</TableCell>
                <TableCell>{row.desc}</TableCell>
                <TableCell>
                  <DynamicProp
                    value={row.resource}
                    name="resource"
                    editMode={false}
                    condensed
                    onEdit={(newValue) => {
                      handleSelectChange(index, "resource", newValue as string);
                    }}
                  />
                </TableCell>
                <TableCell>
                  {row.workCell ? (
                    <DynamicProp
                      value={row.workCell}
                      name="workcell"
                      editMode={false}
                      condensed
                      onEdit={(newValue) => {}}
                    />
                  ) : (
                    <Chip color="error" label="none" />
                  )}
                </TableCell>
                <TableCell>
                  <Chip
                    color={
                      Number(row.complete?.split("/")[0]) >=
                      Number(row.complete?.split("/")[1])
                        ? "success"
                        : Number(row.complete?.split("/")[0]) === 0
                        ? "error"
                        : "warning"
                    }
                    label={
                      getOperationCompletionType(row.opType) === "int"
                        ? row.complete || "?"
                        : getOperationCompletionType(row.opType) === "bool"
                        ? Number(row.complete?.split("/")[0]) ===
                          Number(row.complete?.split("/")[1])
                          ? "Yes"
                          : "No"
                        : (
                            (Number(row.complete?.split("/")[0]) /
                              Number(row.complete?.split("/")[1])) *
                            100
                          ).toFixed(0) + "%"
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
