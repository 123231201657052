import { useContext } from "react";
import { AuthContext } from "../managers/Contexts";
import {
  ObjectType,
  Operation,
  Part,
  Object,
  User,
  WorkOrder,
  WorkCell,
} from "./Typings";

const host =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3030"
    : "https://api.kamtecerpsys.click"; //`https://kam-erp-e1b4d28f0c4b.herokuapp.com`
const queryPropsTree = `{ id name ... on Part { client } ... on Contact { email type } }`;
const dynamicReferenceCache = new Map<string, string>();
let LOCAL_USER_ID = "";

const TIMEOUT = 10000; // Timeout set to 10 seconds (can be adjusted)

function parseDateToLocalTime(date: string) {
  return new Date(date).toLocaleTimeString();
}

let LOCAL_USER_TOKEN = "";

function headers() {
  return {
    Authorization: `Bearer ${LOCAL_USER_TOKEN}`,
    "Content-Type": "application/json",
  };
}

function encodeProperties(props: string[]) {
  return `fields=${encodeURIComponent(props.join(","))}`;
}

// Timeout utility function
const FETCH = (url: string, options: RequestInit, timeout: number) => {
  return new Promise<Response>((resolve, reject) => {
    const timer = setTimeout(
      () => reject(new Error("Request timed out. URL: " + url)),
      timeout
    );
    fetch(url, options)
      .then((response) => {
        clearTimeout(timer);
        resolve(response);
      })
      .catch((error) => {
        clearTimeout(timer);
        reject(error);
      });
  });
};

const ERP = {
  SummarizeDocs: async (collectionID: string, prop: string) => {
    return new Promise<{ [key: string]: number }>(async (resolve, reject) => {
      try {
        const r = await FETCH(
          `${host}/summarize/${collectionID}/${prop}`,
          {
            headers: headers(),
            method: "GET",
          },
          TIMEOUT
        );
        if (!r.ok) reject(r.status);
        const data = await r.json();
        resolve(data);
      } catch (err) {
        reject(err);
      }
    });
  },
  System: {
    GetConfig: async () => {
      return FETCH(
        `${host}/config`,
        { headers: headers(), method: "GET" },
        TIMEOUT
      );
    },
    RefreshAPIConfig: async () => {
      return FETCH(
        `${host}/config/pull`,
        { headers: headers(), method: "POST" },
        TIMEOUT
      );
    },
    Health: async () => {
      try {
        const req = await fetch(`${host}/health`, { headers: headers() });
        return req.ok;
      } catch (err) {
        return false;
      }
    },
  },

  RegisterProgressTransactions: async (
    transactions: { workCell: string; newValue: number }[]
  ) => {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const r = await FETCH(
          `${host}/register-progress-transactions/`,
          {
            headers: headers(),
            method: "POST",
            body: JSON.stringify(transactions),
          },
          TIMEOUT
        );
        if (!r.ok) reject(r.status);
        resolve(true);
        resolve(true);
      } catch (err) {
        reject(err);
      }
    });
  },
  UpdatePartCost: async (partId: string) => {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const r = await FETCH(
          `${host}/updatepartcost/${partId}`,
          {
            headers: headers(),
            method: "POST",
          },
          TIMEOUT
        );
        if (!r.ok) reject(r.status);
        resolve(true);
      } catch (err) {
        reject(false);
      }
    });
  },

  GetObjectList: async (objectType: string) => {
    return new Promise<{ id: string; name: string }[]>(
      async (resolve, reject) => {
        try {
          const r = await FETCH(
            `${host}/objectlist/${objectType}`,
            {
              headers: headers(),
              method: "POST",
            },
            TIMEOUT
          );
          if (!r.ok) reject(r.status);
          const data = await r.json();
          resolve(data);
        } catch (err) {
          reject(err);
        }
      }
    );
  },

  GetObject: async <T extends Object>(
    objectType: ObjectType,
    objectID: string
  ) => {
    return new Promise<T>(async (resolve, reject) => {
      FETCH(
        `${host}/object/${objectType}/${objectID}`,
        { headers: headers() },
        TIMEOUT
      )
        .then((r) => {
          if (!r.ok) return reject(r.statusText);
          r.json().then(resolve);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  GetDefaultObject: async (objectType: ObjectType) => {
    return new Promise<{ [key: string]: string | boolean | number }>(
      async (resolve, reject) => {
        FETCH(
          `${host}/defaultobject/${objectType}`,
          { headers: headers() },
          TIMEOUT
        )
          .then((r) => {
            if (!r.ok) return reject(r.statusText);
            r.json().then(resolve);
          })
          .catch((err) => {
            reject(err);
          });
      }
    );
  },

  GetObjectFiles: async (objectType: ObjectType, objectID: string) => {
    return new Promise<{ name: string; url: string }[]>(
      async (resolve, reject) => {
        try {
          const r = await FETCH(
            `${host}/filelist/${objectType}s/${objectID}`,
            {
              method: "GET",
              headers: headers(),
            },
            TIMEOUT
          );
          if (!r.ok) reject(r.status);
          const packet = await r.json();
          resolve(packet);
        } catch (err) {
          reject(err);
        }
      }
    );
  },

  GetFileURL: (filePath: string) => {
    const url = encodeURI(`${host}/files/${filePath}`);
    const config = { method: "GET", headers: headers() };
    return { url, config };
  },

  UpdateObject: async (
    objectType: ObjectType,
    objectID: string,
    changes: { name: string; value: string | { [key: string]: string }[] }[]
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        FETCH(
          `${host}/updateobject/${objectType}/${objectID}`,
          {
            method: "POST",
            headers: headers(),
            body: JSON.stringify(changes),
          },
          TIMEOUT
        )
          .then((res) => {
            if (!res.ok) throw res.status;
            else resolve(res.body);
          })
          .catch((err) => {
            reject(err);
          });
      } catch (err) {
        reject(err);
      }
    });
  },

  CreateObject: async (
    objectType: ObjectType,
    props: { [key: string]: string }
  ) => {
    return new Promise<Object>(async (resolve, reject) => {
      try {
        FETCH(
          `${host}/createobject/${objectType}`,
          {
            method: "POST",
            headers: headers(),
            body: JSON.stringify(props),
          },
          TIMEOUT
        )
          .then((res) => {
            if (!res.ok) throw res.status;
            else return res;
          })
          .then((res) => res.json())
          .then((res) => resolve(res))
          .catch((err) => {
            reject(err);
          });
      } catch (err) {
        reject(err);
      }
    });
  },

  DeleteObjects: async (objectType: ObjectType, objectIDs: string[]) => {
    return new Promise(async (resolve, reject) => {
      try {
        FETCH(
          `${host}/deleteobjects/${objectType}`,
          {
            method: "POST",
            headers: headers(),
            body: JSON.stringify(objectIDs),
          },
          TIMEOUT
        )
          .then((res) => {
            if (!res.ok) throw res.status;
            else return res;
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      } catch (err) {
        reject(err);
      }
    });
  },

  GetWorkCellsByWO: async <T extends WorkCell>(workOrderId: string) => {
    return new Promise<T[]>(async (resolve, reject) => {
      try {
        const r = await FETCH(
          `${host}/objectlist/workcell?${encodeProperties([
            "opnum",
            "wo",
            "quantity_complete",
            "quantity_total",
          ])}`,
          {
            headers: {
              ...headers(),
              filter: JSON.stringify({ wo: workOrderId }),
            },
            method: "POST",
          },
          TIMEOUT
        );
        if (!r.ok) reject(r.status);
        const packet = await r.json();
        resolve(packet);
      } catch (err) {
        reject(err);
      }
    });
  },

  GetCollectionItem: async (
    objectType: ObjectType,
    id: string,
    properties?: string[]
  ) => {
    //
  },

  GetCollection: async <T extends Object>(
    objectType: ObjectType,
    properties?: string[],
    filter?: { [key: string]: string }
  ) => {
    if (filter) console.log("FILTER: ", filter);
    return new Promise<T[]>(async (resolve, reject) => {
      try {
        const r = await FETCH(
          `${host}/objectlist/${objectType}?${encodeProperties(
            properties || []
          )}`,
          { headers: headers(), method: "POST", body: JSON.stringify(filter) },
          TIMEOUT
        );
        if (!r.ok) reject(r.status);
        const packet = await r.json();
        resolve(packet);
      } catch (err) {
        reject(err);
      }
    });
  },

  GetAnalytics: async () => {
    return new Promise<{ [key: string]: number }>(async (resolve, reject) => {
      try {
        const r = await FETCH(
          `${host}/analytics`,
          {
            headers: headers(),
            method: "GET",
          },
          TIMEOUT
        );
        if (!r.ok) reject(r.status);
        const packet = await r.json();
        resolve(packet);
      } catch (err) {
        reject(err);
      }
    });
  },

  Users: {
    Create: async (data: {
      email: string;
      firstName: string;
      lastName: string;
      userRole: string;
      userPerms: string;
    }) => {
      return new Promise<{ link: string }>(async (resolve, reject) => {
        try {
          const r = await FETCH(
            `${host}/createUser`,
            {
              headers: headers(),
              method: "POST",
              body: JSON.stringify(data),
            },
            TIMEOUT
          );
          if (!r.ok) reject(r.status);
          const packet = await r.json();
          resolve({ link: packet.link });
        } catch (err) {
          reject(err);
        }
      });
    },
    GetUserData: async (user_id?: string) => {
      return new Promise<User>((resolve, reject) => {
        ERP.GetObject<User>("user", user_id || LOCAL_USER_ID)
          .then((user) => {
            resolve(user);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    GetTimeclockSummary: async (user_id?: string) => {
      return new Promise<
        { time_in: string; time_out?: string; time_duration?: number }[]
      >(async (resolve, reject) => {
        try {
          const r = await FETCH(
            `${host}/timeclock/summary/${user_id || LOCAL_USER_ID}`,
            { headers: headers() },
            TIMEOUT
          );
          if (!r.ok) reject(r.status);
          const packet = (await r.json()) as {
            time_in: { _seconds: number };
            time_out?: { _seconds: number };
            time_duration?: number;
          }[];
          resolve(
            packet.map((punch) => {
              return {
                time_in: new Date(
                  punch.time_in._seconds * 1000
                ).toLocaleString(),
                time_out: punch.time_out
                  ? new Date(punch.time_out._seconds * 1000).toLocaleString()
                  : undefined,
                time_duration: punch.time_duration,
              };
            })
          );
        } catch (err) {
          reject(err);
        }
      });
    },
  },

  LocalUser: {
    SetCredential: (id: string, token: string) => {
      LOCAL_USER_ID = id;
      LOCAL_USER_TOKEN = token;
    },
    ID: () => {
      return LOCAL_USER_ID;
    },
    ClockIn: async () => {
      return new Promise<string>(async (resolve, reject) => {
        try {
          const r = await FETCH(
            `${host}/timeclock/in/${LOCAL_USER_ID}`,
            {
              headers: headers(),
              method: "POST",
            },
            TIMEOUT
          );
          if (!r.ok) reject(r.status);
          const packet = await r.json();
          resolve(parseDateToLocalTime(packet.timestamp));
        } catch (err) {
          reject(err);
        }
      });
    },
    ClockOut: async () => {
      return new Promise<string>(async (resolve, reject) => {
        try {
          const r = await FETCH(
            `${host}/timeclock/out/${LOCAL_USER_ID}`,
            {
              headers: headers(),
              method: "POST",
            },
            TIMEOUT
          );
          if (!r.ok) reject(r.status);
          const packet = await r.json();
          resolve(parseDateToLocalTime(packet.timestamp));
        } catch (err) {
          reject(err);
        }
      });
    },
    ClockStatus: async (id: string) => {
      return new Promise<boolean>(async (resolve, reject) => {
        try {
          const r = await FETCH(
            `${host}/timeclock_status/${id}`,
            {
              headers: headers(),
              method: "GET",
            },
            TIMEOUT
          );
          if (!r.ok) reject(r.status);
          const packet = await r.json();
          resolve(packet.status);
        } catch (err) {
          reject(err);
        }
      });
    },
  },

  Resources: {
    GetResource: async (resource_ref: string) => {},
    GetAllResources: async () => {},
  },

  ResolveDynamicProp: async (
    referenceType: ObjectType,
    referenceId: string
  ) => {
    return new Promise<string>(async (resolve, reject) => {
      const ref = dynamicReferenceCache.get(referenceId);
      if (ref) {
        resolve(ref);
      } else {
        ERP.GetCollection<Object>(referenceType, ["name"]).then((objects) => {
          objects.forEach((obj) => {
            dynamicReferenceCache.set(obj.id, obj.name);
          });
          const nref = dynamicReferenceCache.get(referenceId);
          if (nref) resolve(nref);
          reject("Something went wrong.");
        });
      }
    });
  },

  Parts: {
    GetPartData: async (part_id: string) => {
      return new Promise<Part>(async (resolve, reject) => {
        try {
          const r = await FETCH(
            `${host}/graphql?query={ getPart(id: "${part_id}") { id name customer }}`,
            {
              headers: headers(),
            },
            TIMEOUT
          );
          if (!r.ok) reject(r.status);
          const packet = await r.json();
          resolve(packet.data.getPart);
        } catch (err) {
          reject(err);
        }
      });
    },
    GetPartOperations: async (type: "part" | "estimate", part_id: string) => {
      return new Promise<Operation[]>(async (resolve, reject) => {
        try {
          const r = await FETCH(
            `${host}/object/${type}/${part_id}`,
            {
              headers: headers(),
            },
            TIMEOUT
          );
          if (!r.ok) reject(r.status);
          resolve((await r.json()).operations as []);
        } catch (err) {
          reject(err);
        }
      });
    },
    UpdatePartOperations: async (
      type: "part" | "estimate",
      part_id: string,
      operations: Operation[]
    ) => {
      return new Promise<Operation[]>(async (resolve, reject) => {
        try {
          const r = await FETCH(
            `${host}/operations/update/${type}/${part_id}`,
            {
              headers: headers(),
              method: "POST",
              body: JSON.stringify(operations),
            },
            TIMEOUT
          );
          if (!r.ok) reject(r.status);
          resolve(await r.json());
        } catch (err) {
          reject(err);
        }
        resolve(operations);
      });
    },
    GetAllParts: async () => {
      return new Promise<Part[]>(async (resolve, reject) => {
        try {
          const r = await FETCH(
            `${host}/graphql?query={ getAllParts { id name customer }}`,
            {},
            TIMEOUT
          );
          if (!r.ok) reject(r.status);
          const packet = await r.json();
          resolve(packet.data.getAllParts);
        } catch (err) {
          reject(err);
        }
      });
    },
  },
};

export default ERP;
