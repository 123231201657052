/* eslint-disable import/no-anonymous-default-export */
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ERP from "../../util/ERP";

export default () => {
  const [value, setValue] = useState("?");
  useEffect(() => {
    const getData = () => {
      ERP.GetCollection("time_series_data" as unknown as "contact", [
        "data",
      ]).then((data) => {
        try {
          const nData = (
            data.filter(
              (a) => a.id === new Date().toISOString().split("T")[0]
            )[0] as unknown as { data: { [key: string]: number } }
          ).data;
          let total = 0;
          for (let x in nData) {
            const v = nData[x];
            total += v;
          }
          setValue(`${total.toFixed(2)}`);
        } catch (err) {
          //
        }
      });
    };

    getData();
  }, []);
  return (
    <Box>
      <Typography variant="h6">TOTAL</Typography>
      <Typography color="green" variant="h1">
        ${value}
      </Typography>
    </Box>
  );
};
